import React, { useEffect } from "react";
import "./terms.scss";

const TermsAndCondition = () => {
  useEffect(() => {
    document.getElementById("parent").scrollIntoView();
  }, []);

  const TermDiscriptipn = ({ heading, disc }) => {
    return (
      <>
        <p className="Heading_Text">{heading}</p>
        <div className="disrciption_Text_Terms">{disc}</div>
      </>
    );
  };

  return (
    <section id="parent" className="Parent_Container_Terms">
      <div className="Terms_Content_Container">
        <p className="Terms_Text">Terms and conditions</p>
        <div className="Text_Conatiner">
          <TermDiscriptipn
            disc={
              "1.	Acceptance of Terms. By using SeeVitals, you acknowledge and agree to these Terms of Service, as well as our Privacy Policy. If you do not agree to these Terms of Service, you should not use the application."
            }
          />
          <TermDiscriptipn
            disc={
              "2.	Use of Application. SeeVitals is intended to be used as a tool to monitor your health conditions and remind you of your medications. You agree not to use SeeVitals for any unlawful or unauthorized purpose, and you will comply with all applicable laws and regulations."
            }
          />
          <TermDiscriptipn
            disc={
              "3.	User Accounts. In order to use SeeVitals, you must create a user account. You are responsible for maintaining the confidentiality of your account information, including your password, and for all activities that occur under your account."
            }
          />
          <TermDiscriptipn
            disc={
              "4.	Community Feature. SeeVitals offers a community feature that allows users to engage with one another and discuss their health concerns. By using this feature, you acknowledge and agree to the following:"
            }
          />
          <TermDiscriptipn
            disc={
              "•	You are solely responsible for any content that you post on the community feature, including any personal information you choose to disclose."
            }
          />
          <TermDiscriptipn
            disc={
              "•	SeeVitals reserves the right to remove any content that is deemed inappropriate or violates these Terms of Service."
            }
          />
          <TermDiscriptipn
            disc={
              "•	SeeVitals is not responsible for any content posted by users on the community feature."
            }
          />
          <TermDiscriptipn
            disc={
              "5.	Intellectual Property. SeeVitals and its content, including all intellectual property rights, are owned by SeeVitals. You agree not to copy, modify, or distribute any part of the application or its content without our prior written consent."
            }
          />
          <TermDiscriptipn
            disc={
              "6.	Disclaimer of Warranties. SeeVitals makes no warranties or representations about the accuracy or completeness of the information provided through the application. You use the application at your own risk."
            }
          />
          <TermDiscriptipn
            disc={
              "7.	Limitation of Liability. SeeVitals shall not be liable for any damages arising from the use or inability to use the application, including but not limited to direct, indirect, incidental, consequential, or punitive damages."
            }
          />
          <TermDiscriptipn
            disc={
              "8.	Modifications to Terms of Service. SeeVitals may modify these Terms of Service at any time, and such modifications shall be effective immediately upon posting. Your continued use of the application after any modifications to these Terms of Service shall constitute your acceptance of such modifications."
            }
          />
          <TermDiscriptipn
            disc={
              "9.	Termination. SeeVitals may terminate your access to the application at any time, with or without cause, and without notice to you."
            }
          />
        </div>
      </div>
    </section>
  );
};

export default TermsAndCondition;
