import React from "react";
import { Home, AboutUs, Contact } from "./screens";
import { Routes, Route } from "react-router-dom";
import { Header, Footer } from "./components";
import PrivacyPolicy from "./screens/PrivacyPolicy/PrivacyPolicy";
import TermsAndCondition from "./screens/TermsAndCondition/TermsAndCondition";

export default function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<AboutUs />} />
        <Route path="contact" element={<Contact />} />
        <Route path="privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="termsAndCondition" element={<TermsAndCondition />} />
      </Routes>
      <Footer />
    </div>
  );
}
