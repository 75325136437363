import React, { useEffect } from "react";
import "./privacyPolicy.scss";

const PrivacyPolicy = () => {
  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth",
    // });
    // document.getElementById("parent").scrollTo({ top: 0, behavior: "smooth" });
    document.getElementById("parent").scrollIntoView();
  }, []);
  const TermDiscriptipn = ({ heading, disc }) => {
    return (
      <>
        <p className="Heading_Text">{heading}</p>
        <div className="disrciption_Text_Terms">{disc}</div>
      </>
    );
  };
  return (
    <section id="parent" className="Parent_Container_Privacy">
      <div className="Privacy_Content_Container">
        <p className="Privacy_Text">Privacy Policy</p>
        <div className="Text_Conatiner">
          <p className="Header_Points_Text">
            SeeVitals respects your privacy and is committed to protecting your
            personal information. This Privacy Policy describes how we collect,
            use, and disclose your personal information.
          </p>
          <TermDiscriptipn
            disc={
              "1.	Information We Collect. We collect personal information that you provide to us when you create a user account, use the community feature, or use the health monitoring features of the application. This may include your name, email address, date of birth, medical conditions, and medication information."
            }
          />
          <TermDiscriptipn
            disc={
              "2.	Use of Information. We use your personal information to provide you with the health monitoring features of the application and to facilitate your use of the community feature. We may also use your personal information to send you information about our products and services."
            }
          />
          <TermDiscriptipn
            disc={
              "3.	Disclosure of Information. We may disclose your personal information to third-party service providers that help us operate and maintain the application, as well as to comply with legal requirements."
            }
          />
          <TermDiscriptipn
            disc={
              "4.	Security. We take reasonable measures to protect your personal information from unauthorized access or disclosure."
            }
          />
          <TermDiscriptipn
            disc={
              "5.	Children's Privacy. SeeVitals is not intended for use by children under the age of 13, and we do not knowingly collect personal information from children under the age of 13. If you are under the age of 13, do not use SeeVitals or provide any personal information to us."
            }
          />
          <TermDiscriptipn
            disc={
              "6.	Third-Party Links. SeeVitals may contain links to third-party websites or applications that are not operated by us. We are not responsible for the privacy practices of these third-party websites or applications, and we encourage you to review their privacy policies before providing any personal information."
            }
          />
          <TermDiscriptipn
            disc={
              "7.	Changes to Privacy Policy. SeeVitals may modify this Privacy Policy at any time, and such modifications shall be effective immediately upon posting. Your continued use of the application after any modifications to this Privacy Policy shall constitute your acceptance of such modifications."
            }
          />
          <TermDiscriptipn
            disc={
              "8.	Contact Us. If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at info@seevitals.com"
            }
          />
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
